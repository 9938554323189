<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a-select
          :options="filterOptions"
          class="mr-2"
          style="width: 300px;"
          placeholder="Semua Kategori"
          v-model:value="filterCategory"
        ></a-select>
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="mt-2 ml-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-2"
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Tambah Faq"
          @click="
            () => {
              formState = {}
              formState.status_approval = 0
              formState.type = 1
              formState.content = ''
              setModal1Visible(true)
              formState.title_state = 'Tambah FAQ'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #category="{ record }">
          <span>
            {{ record.category.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()) }}
          </span>
        </template>
        <template #content="{record}">
          <span v-html="record.content"></span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat FAQ">
              <a href="javascript: void(0);" class="btn btn-sm m-1" @click="view(record.id)">
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Edit FAQ">
              <a href="javascript: void(0);" class="btn btn-sm m-1" @click="edit(record.id)">
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus FAQ">
              <a href="javascript: void(0);" class="btn btn-sm m-1" @click="postDelete(record.id)">
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>

  <a-modal
    v-model:visible="modal1Visible"
    :title="formState.title_state"
    :destroy-on-close="true"
    :mask-closable="false"
    width="1000px"
    @ok="modal1Visible = false"
  >
    <pre v-if="!true">{{ formState }}</pre>
    <template #footer>
      <a-button
        :loading="isPosting"
        key="submit"
        type="primary"
        @click="submit"
        :hidden="formState.readOnly"
        >Simpan</a-button
      ></template
    >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      class="myform"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama FAQ"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="name"
          >
            <a-input
              width="100%"
              v-model:value="formState.name"
              :disabled="formState.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kategori FAQ"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="category">
            <a-auto-complete
              v-model:value="formState.category"
              :options="options"
              class="w-100"
              :filter-option="filterOption"
              :disabled="formState.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Konten FAQ"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="content"
          >
            <quill-editor
              class="mb-2"
              v-model:content="formState.content"
              content-type="html"
              toolbar="full"
              :read-only="formState.readOnly"
              :disabled="formState.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Entitas"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            :max-tag-count="3"
            required
            name="vendor_type"
          >
            <a-select
              :options="[
                { value: 0, label: 'Bisnis Kokoh' },
                { value: 2, label: 'Toko' },
                { value: 3, label: 'Toko Kecil' },
                { value: 1, label: 'Distributor' },
                { value: 1105, label: 'Large Toko' },
              ]"
              class="mr-2"
              stylea="width: 300px;"
              placeholder="Pilih Entitas"
              :disabled="formState.readOnly"
              v-model:value="formState.vendor_type"
              required
            >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row> -->
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, provide, toRefs, computed } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import qs from 'qs'

const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Nama',
    dataIndex: 'name',
  },
  {
    title: 'Kategori',
    dataIndex: 'category',
    slots: { customRender: 'category' },
  },
  // {
  //   title: 'Konten',
  //   dataIndex: 'content',
  //   slots: { customRender: 'content' },
  // },
  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]

export default defineComponent({
  name: 'MFaq',
  components: {},

  setup() {
    const loading = ref(false)
    const modal1Visible = ref(false)
    const formState = ref({})
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    columns.value = acolumns

    const data = ref([])
    const dataKategori = ref([])
    const current1 = ref(1)
    const q = ref('')
    const filterCategory = ref(null)

    const errorMessage = ref(null)
    const status = ref(null)
    const modalRef = ref('')
    const isPosting = ref(false)
    const value1 = ref(true)
    const value2 = ref(false)

    const formRef = ref('')
    const options = reactive([])
    const filterOptions = reactive([])

    const filterOption = (input, option) => {
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    }

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/faqs', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `FAQ_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        q: q.value,
        category: filterCategory.value,
        status: status.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/faqs', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items

          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchDataKategori = () => {
      const _params = {
        q: q.value,
        get_category: 'get_category',
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/faqs', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          dataKategori.value = items
          
          options.splice(0)
          dataKategori.value.forEach((value, index) => {
            options.push({value : value.category})
          });

          filterOptions.splice(0)
          filterOptions.push({value: null, label: 'Semua'})
          dataKategori.value.forEach((value, index) => {
            filterOptions.push({value : value.category})
          });
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const view = id => {
      apiClient
        .get(`/api/faqs/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Lihat FAQ'
          formState.value.state = 'lihat'
          formState.value.readOnly = true
        })
        .catch(error => console.error(error))
    }

    const edit = id => {
      apiClient
        .get(`/api/faqs/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Edit FAQ'
          formState.value.state = 'edit'
          formState.value.readOnly = false
        })
        .catch(error => console.error(error))
    }

    const submit = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi ',
          content: 'Apakah anda ingin melanjutkan tambah FAQ ?',

          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            apiClient
              .post('/api/faqs', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchDataKategori()
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan!'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Ubah Subjek Layanan',
          content: 'Apakah anda ingin melanjutkan edit FAQ ?',
          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            // TODO: validasi
            apiClient
              .post('/api/faqs/' + formState.value.id + '?_method=PUT', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchDataKategori()
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan!'))
              .finally(e => {
                isPosting.value = false
              })
          },
          onCancel() {},
        })
      }
    }

    const postDelete = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus',
        content: 'Apakah anda ingin Hapus FAQ ?',
        onOk() {
          apiClient
            .delete(`/api/faqs/${id}`)
            .then(response => {
              const data = response.data
              formState.value = data
              fetchDataKategori()
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }

    provide('formState', formState)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const search = () => {
      // fetchDataKategori()
      fetchData()
    }

    onMounted(() => {
      fetchDataKategori()
      fetchData()
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }
    const visible = ref(false)

    const validate = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState))
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    const rules = {
      name: {
        required: true,
        message: 'Masukan Nama FAQ',
      },
      category: {
        required: true,
        message: 'Masukan Kategori FAQ',
      },
      content: {
        required: true,
        message: 'Isi Konten',
      },

      vendor_type: {
        required: true,
        message: 'Pilih Entitas',
      },
    }

    return {
      modal1Visible,
      loading,
      visible,
      view,
      handleOk,
      formState,
      setModal1Visible,
      data,
      dataKategori,
      columns,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      searchText,
      searchInput,
      fetchXlsx,
      q,
      filterCategory,
      search,
      startRow,
      state,
      ...toRefs(state),
      fetchData,
      fetchDataKategori,
      handleTableChange,
      modalRef,
      handleReset,
      handleSearch,
      onSelectChange,
      status,
      submit,
      isPosting,
      edit,
      postDelete,
      value1,
      value2,
      errorMessage,
      validate,
      rules,
      formRef,
      options,
      filterOptions,
      filterOption,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
